import { CSSProperties, Dispatch, SetStateAction } from "react";

import * as styles from "./RadioListSelector.module.scss";

import MultipleSelectableList from "components/SelectableList/MultipleSelectableList";
import Translate, { TranslatableText } from "components/Translate/Translate";

interface Props {
    title?: TranslatableText;
    itemList: Array<{
        id: number;
        name: string;
    }>;
    selectedIdsList: number[] | undefined;
    setSelectedIdsList: Dispatch<SetStateAction<number[] | undefined>>;
    styleOverrides?: CSSProperties;
    listStyleOverrides?: CSSProperties;
}

const RadioListMultipleSelector = ({
    title,
    itemList,
    selectedIdsList,
    setSelectedIdsList,
    styleOverrides,
    listStyleOverrides,
}: Props) => {
    return (
        <>
            {title && (
                <div className={styles.title}>
                    <Translate text={title} />
                </div>
            )}
            <div
                style={{
                    padding: "20px 0",
                    width: "100%",
                    overflow: "auto",
                    ...styleOverrides,
                }}
            >
                <MultipleSelectableList
                    list={itemList}
                    selectedIdsList={selectedIdsList}
                    setSelectedIdsList={setSelectedIdsList}
                    styleOverrides={listStyleOverrides}
                />
            </div>
        </>
    );
};

export default RadioListMultipleSelector;
