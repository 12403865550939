import { useContext, useState } from "react";
import dayjs from "dayjs";
import {
    faCircle,
    faCircleUser,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, TextField, Typography } from "@mui/material";

import * as styles from "./CommentsPanel.module.scss";
import { CollectionContext } from "../state/CollectionContext";
import { useAddCommentMutation } from "hooks/api/collections";
import useUserQuery from "hooks/api/useUserQuery";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import Translate from "components/Translate/Translate";

const AddCommentButton = () => {
    const { collection } = useContext(CollectionContext);
    const { data: userData } = useUserQuery();
    const name =
        userData.me.firstName && userData.me.lastName
            ? userData.me.firstName +
              " " +
              userData.me.lastName.substring(0, 1) +
              "."
            : userData?.me.email;
    const [isAddingComment, setIsAddingComment] = useState<boolean>(false);
    const [comment, setComment] = useState<string>("");
    const addCommentMutation = useAddCommentMutation();
    const addCommentHandler = (comment: string) => {
        tracker("Add Comment to Collection", { comment });
        resetCommentHandler();
        addCommentMutation.mutateAsync({
            collectionId: collection.id,
            comment,
        });
    };
    const resetCommentHandler = () => {
        setIsAddingComment(false);
        setComment("");
    };
    return !isAddingComment ? (
        <Button
            className={styles.addComment}
            onClick={() => {
                tracker("Add Comment Button Clicked");
                setIsAddingComment(true);
            }}
        >
            <FontAwesomeIcon icon={faPlus} fontSize={12} />
            <Typography variant="text-size-xSmall-bold">
                <Translate text="Add Comment" />
            </Typography>
        </Button>
    ) : (
        <div className={styles.newComment}>
            <div className={styles.commentHeader}>
                <Typography
                    variant="text-size-xxSmall-medium"
                    color="$text-colors-color-text-dark.main"
                    className={styles.iconAndName}
                >
                    <FontAwesomeIcon icon={faCircleUser} fontSize={14} />
                    <span className={styles.name}>{name}</span>
                </Typography>
                <FontAwesomeIcon
                    icon={faCircle}
                    color={variables["icon-colors-green-60"]}
                    fontSize={4}
                />
                <Typography
                    variant="text-size-xxSmall-medium"
                    color="$text-colors-color-text-gray.main"
                >
                    {dayjs(new Date()).format("DD/MM/YY")}
                </Typography>
            </div>
            <TextField
                value={comment}
                placeholder="Enter your comment."
                fullWidth
                multiline
                minRows={3}
                onBlur={(e) => setComment(e.target.value)}
                onChange={(e) => setComment(e.target.value)}
            />
            <div className={styles.newCommentFooter}>
                <Button
                    variant="text"
                    className={styles.footerButton}
                    onClick={resetCommentHandler}
                >
                    <Translate text="Cancel" />
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    className={styles.footerButton}
                    disabled={comment?.length === 0}
                    onClick={() => addCommentHandler(comment)}
                >
                    <Translate text="Save" />
                </Button>
            </div>
        </div>
    );
};

export default AddCommentButton;
