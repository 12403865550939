@use "../../styles/variables.module";

.listContainer {
    margin: 0;
    padding: 0 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5;
    overflow-y: auto;
}
