import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { axiosAPI } from "./axiosAPI";
import { Role } from "types";
import { Request } from "types/collaboration";

export const useChangeRoleMutation = () => {
    return useMutation(
        async ({
            elementId,
            userId,
            role,
        }: {
            elementId: string;
            userId: string;
            role: Role;
        }) => {
            return await axiosAPI
                .getInstance()
                .post(`/content/collaboration/role/grant`, {
                    userId,
                    shareId: elementId,
                    role,
                });
        },
    );
};

export const useReviewRequestsQuery = () => {
    return useQuery<Request[], AxiosError>(["review-requests"], async () => {
        const { data } = await axiosAPI
            .getInstance()
            .get(`/content/collaboration/review-requests`, {
                data: {
                    filters: {
                        type: ["reviewRequest"],
                    },
                    sort: {
                        field: "timestamp",
                        order: "desc",
                    },
                },
            });
        return data;
    });
};
