import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

import * as styles from "./MenuItem.module.scss";

import Translate, { TranslatableText } from "components/Translate/Translate";

interface Props {
    faIcon: IconDefinition;
    iconColor: "Blue" | "Green";
    text: TranslatableText | JSX.Element;
    clickHandler: () => void;
}

const MenuItem = ({ faIcon, iconColor, text, clickHandler }: Props) => {
    return (
        <div className={styles.item} onClick={clickHandler}>
            <div
                className={`${styles.icon} ${styles[`iconColor${iconColor}`]}`}
            >
                <FontAwesomeIcon icon={faIcon} fontSize={12} />
            </div>
            {text &&
                (typeof text === "string" ? (
                    <Typography
                        variant="text-size-Base"
                        color="$text-colors-color-text-dark.main"
                    >
                        <Translate text={text} />
                    </Typography>
                ) : (
                    text
                ))}
        </div>
    );
};

export default MenuItem;
