import { useContext, useState } from "react";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import {
    faAngleDoubleDown,
    faAnglesUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";
import {
    CollectionItem,
    CollectionResourceResult,
    PublicationStatus,
} from "types";
import { convertItemTypeToResultType } from "utils/typeConvertors";

import SortableGridResult from "./components/SortableGridResult";
import AddResourcePanel from "../AddResourcesPanel/AddResourcePanel";
import CollectionGridRejectedResultCard from "components/CollectionViewer/components/CollectionGridView/components/CollectionGridResultCard/CollectionGridRejectedResultCard";
import Translate from "components/Translate/Translate";

const ResourcesContainerGridView = () => {
    const {
        selectedItems,
        highlightedItems,
        updateItemPublicationStatus,
        itemReviewRequest,
        itemReviewSubmit,
        removeItem,
    } = useContext(CollectionContext);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const partition: {
        items: CollectionItem[];
        rejectedItems: CollectionItem[];
    } = {
        items: [],
        rejectedItems: [],
    };
    selectedItems.forEach((item) => {
        if (item.reviewStatus !== "REJECTED") {
            partition.items.push(item);
        } else {
            partition.rejectedItems.push(item);
        }
    });
    const results: CollectionResourceResult[] = [
        ...partition.items,
        ...partition.rejectedItems,
    ].map((item) => convertItemTypeToResultType(item));
    return (
        <>
            <AddResourcePanel />
            {partition.items.length > 0 && (
                <SortableContext
                    items={partition.items}
                    strategy={rectSortingStrategy}
                >
                    <Grid
                        container
                        spacing={"20px"}
                        style={{ overflow: "hidden" }}
                    >
                        {partition.items.map((item, index) => (
                            <Grid key={item.url} md={6} xs={12}>
                                <SortableGridResult
                                    id={item.id}
                                    results={results}
                                    resultIndex={index}
                                    deleteItemHandler={() =>
                                        removeItem && removeItem(item)
                                    }
                                    updateItemPublicationStatusHandler={(
                                        publicationStatus: PublicationStatus,
                                    ) =>
                                        updateItemPublicationStatus(
                                            item,
                                            publicationStatus,
                                        )
                                    }
                                    itemReviewRequestHandler={(
                                        requestedReviewer: "WIZENOZE" | "OWNER",
                                        comment?: string | undefined,
                                    ) =>
                                        itemReviewRequest(
                                            item,
                                            requestedReviewer,
                                            comment,
                                        )
                                    }
                                    itemReviewSubmitHandler={(
                                        action: "APPROVE" | "REJECT",
                                        actionDetails?: string[],
                                        comment?: string,
                                    ) =>
                                        itemReviewSubmit(
                                            item,
                                            action,
                                            actionDetails,
                                            comment,
                                        )
                                    }
                                    isHighlighted={highlightedItems.includes(
                                        item.id,
                                    )}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </SortableContext>
            )}
            {partition.rejectedItems.length > 0 && (
                <div
                    style={{
                        paddingTop: 30,
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #e8e7e9",
                        }}
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        <Typography variant="text-size-Base-bold">
                            <Translate text="Rejected resources" />
                        </Typography>
                        <FontAwesomeIcon
                            icon={!isCollapsed ? faAnglesUp : faAngleDoubleDown}
                            onClick={() => setIsCollapsed(!isCollapsed)}
                        />
                    </div>
                    <Collapse in={!isCollapsed}>
                        <Grid
                            container
                            spacing={"20px"}
                            style={{ overflow: "hidden" }}
                        >
                            {partition.rejectedItems.map((item, index) => (
                                <Grid key={item.url} md={6} xs={12}>
                                    <CollectionGridRejectedResultCard
                                        results={results}
                                        resultIndex={
                                            partition.items.length + index
                                        }
                                        deleteItemHandler={() =>
                                            removeItem && removeItem(item)
                                        }
                                        reviewRequestHandler={(
                                            requestedReviewer:
                                                | "WIZENOZE"
                                                | "OWNER",
                                            comment?: string | undefined,
                                        ) =>
                                            itemReviewRequest(
                                                item,
                                                requestedReviewer,
                                                comment,
                                            )
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Collapse>
                </div>
            )}
        </>
    );
};

export default ResourcesContainerGridView;
