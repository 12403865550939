import { Chip, Typography } from "@mui/material";

import variables from "styles/_variables.module.scss";
import { ReviewStatus } from "types";
import { capitaliseFirstLetter } from "utils/oldhelpers";

import Translate from "components/Translate/Translate";

interface Props {
    reviewStatus: ReviewStatus;
}

const ReviewStatusChip = ({ reviewStatus }: Props) => {
    if (!reviewStatus || reviewStatus === "NONE") return <></>;
    return reviewStatus === "PENDING_REVIEW" ? (
        <Chip
            sx={{
                background: variables["message-colors-color-message-warning"],
                borderColor: variables["message-colors-color-message-warning"],
            }}
            label={
                <Typography variant="text-size-xxSmall-medium">
                    <Translate text="Pending review" />
                </Typography>
            }
        />
    ) : (
        <Chip
            variant="outlined"
            sx={{
                color:
                    reviewStatus === "REJECTED"
                        ? variables["message-colors-color-message-danger"]
                        : variables["message-colors-color-message-success"],
                borderColor:
                    reviewStatus === "REJECTED"
                        ? variables["message-colors-color-message-danger"]
                        : variables["message-colors-color-message-success"],
            }}
            label={
                <Typography variant="text-size-xxSmall-medium">
                    <Translate
                        text={capitaliseFirstLetter(reviewStatus.toLowerCase())}
                    />
                </Typography>
            }
        />
    );
};

export default ReviewStatusChip;
