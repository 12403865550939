import dayjs from "dayjs";
import { Chip, Typography } from "@mui/material";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "../ResultCard.module.scss";
import premiumSVG from "images/premium.svg";
import teacherApprovedSVG from "images/teacher-approved.svg";
import teacherContentSVG from "images/teacher-content.svg";
import variables from "styles/_variables.module.scss";
import { AnyResource } from "types";
import { isAppMode } from "utils/newtools";

import PublicationStatusChip from "./PublicationStatusChip";
import Translate from "components/Translate/Translate";
import ReviewStatusChip from "./ReviewStatusChip";

const MetaDataFooter = ({
    readingLevel,
    result,
}: {
    readingLevel: number;
    result: AnyResource;
}) => {
    const resultMetadata =
        result.resultType === "VIDEO" ? result.metadata : undefined;
    const hasSubtitles = resultMetadata && resultMetadata?.caption;
    const videoDuration = resultMetadata && resultMetadata?.duration;
    const showMetadataFooter =
        (result.source && result.source.type === "PAID") ||
        result.isUserProvided ||
        result.teacherApproved ||
        readingLevel ||
        (isAppMode && (result.embeddable || hasSubtitles || videoDuration));
    if (!showMetadataFooter) return <></>;
    return (
        <div className={styles.footer}>
            {result.source?.type === "PAID" && (
                <Chip
                    variant="outlined"
                    sx={{ color: "#F9A620", borderColor: "#F9A620" }}
                    label={
                        <div className={styles.footerChip}>
                            <img src={premiumSVG} alt="premium" />
                            <Typography variant="text-size-xxSmall-medium">
                                <Translate text="Premium" />
                            </Typography>
                        </div>
                    }
                />
            )}
            {result.isUserProvided && (
                <Chip
                    variant="outlined"
                    sx={{ color: "#1992C5", borderColor: "#1992C5" }}
                    label={
                        <div className={styles.footerChip}>
                            <img
                                src={teacherContentSVG}
                                alt="educator content"
                            />
                            <Typography variant="text-size-xxSmall-medium">
                                <Translate text="Educator content" />
                            </Typography>
                        </div>
                    }
                />
            )}
            {result.teacherApproved && (
                <Chip
                    variant="outlined"
                    sx={{ color: "#DE6011", borderColor: "#DE6011" }}
                    label={
                        <div className={styles.footerChip}>
                            <img
                                src={teacherApprovedSVG}
                                alt="handpicked by educators"
                            />
                            <Typography variant="text-size-xxSmall-medium">
                                <Translate text="Handpicked by educators" />
                            </Typography>
                        </div>
                    }
                />
            )}
            {readingLevel && (
                <div className={styles.readingLevel}>
                    <Typography
                        variant="text-size-xxSmall-medium"
                        color="$icon-colors-green-100.main"
                    >
                        <Translate text="Reading Level" />
                    </Typography>
                    <div
                        className={styles.readingLevelBubble}
                        style={{
                            backgroundColor:
                                variables[
                                    "reading-levels-color-level-" + readingLevel
                                ],
                        }}
                    >
                        <Typography
                            variant="text-size-xSmall-bold"
                            color="$text-colors-color-text-white.main"
                        >
                            {readingLevel}
                        </Typography>
                    </div>
                </div>
            )}
            {isAppMode && result.embeddable && (
                <Chip
                    label={
                        <Typography
                            variant="text-size-xxSmall-medium"
                            color="$text-colors-color-text-white.main"
                        >
                            <Translate text="Embeddable" />
                        </Typography>
                    }
                />
            )}
            {isAppMode && hasSubtitles && (
                <Chip
                    label={
                        <Typography
                            variant="text-size-xxSmall-medium"
                            color="$text-colors-color-text-white.main"
                        >
                            <Translate text="Subtitles" />
                        </Typography>
                    }
                />
            )}
            {/* TODO: No reading duration in response yet
                {result.readingDuration && (
                    <>
                        {(readingLevel ||
                            result.options.embeddable ||
                            result.metadata?.caption) && (
                            <FontAwesomeIcon
                                icon={faCircle}
                                className={styles.divider}
                            />
                        )}
                        <Typography
                            variant="text-size-xxSmall-medium"
                            color="$icon-colors-green-60.main"
                        >
                            {result.readingDuration}
                            <Translate text=" min read" />
                        </Typography>
                    </>
                )} */}
            {isAppMode && resultMetadata && videoDuration && (
                <>
                    {(readingLevel || result.embeddable || hasSubtitles) && (
                        <FontAwesomeIcon
                            icon={faCircle}
                            className={styles.divider}
                        />
                    )}
                    <Typography
                        variant="text-size-xxSmall-medium"
                        color="$icon-colors-green-60.main"
                    >
                        <Translate text="Length " />
                        {dayjs
                            .duration(videoDuration)
                            .format(
                                videoDuration.includes("H")
                                    ? "H:mm:ss"
                                    : "m:ss",
                            )}
                        <Translate text=" min" />
                    </Typography>
                </>
            )}
            {isAppMode &&
                (result.publicationStatus ||
                    (result.reviewStatus &&
                        result.reviewStatus !== "NONE")) && (
                    <>
                        <FontAwesomeIcon
                            icon={faCircle}
                            className={styles.divider}
                        />
                        {result.reviewStatus !== "REJECTED" && (
                            <PublicationStatusChip
                                publicationStatus={result.publicationStatus}
                            />
                        )}
                        <ReviewStatusChip reviewStatus={result.reviewStatus} />
                    </>
                )}
            {/* TODO: No last updated date in response yet. Use dayjs to calculate relativeTime from now
                {result.lastUpdatedDate && (
                    <>
                        {(readingLevel ||
                            result.options.embeddable ||
                            result.metadata?.caption ||
                            result.readingDuration ||
                            result.metadata?.duration) && (
                            <FontAwesomeIcon
                                icon={faCircle}
                                className={styles.divider}
                            />
                        )}
                        <Typography
                            variant="text-size-xxSmall-medium"
                            color="$icon-colors-green-60.main"
                        >
                            <Translate text="Updated " />
                            {use dayjs relative time}
                        </Typography>
                    </>
                )} */}
        </div>
    );
};

export default MetaDataFooter;
