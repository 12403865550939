@use "../../../styles/variables.module";

.container {
    padding: 10px 15px;
    margin: 0px -20px -10px;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    background: variables.$background-colors-color-background-light;
    border-top: 1px solid variables.$border-colors-color-border-light;
}

.buttonStyle {
    padding: 5px 10px;
    height: 24px;
    display: flex;
    align-items: center;
    gap: 5px;
}
