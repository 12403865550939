import {
    DraggableAttributes,
    DraggableSyntheticListeners,
} from "@dnd-kit/core";
import { Tooltip, Typography } from "@mui/material";
import { faPlayCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "./ResultCard.module.scss";
import { useResultModalContext } from "contexts/ResultModalContext";
import variables from "styles/_variables.module.scss";
import { AnyResource, PublicationStatus } from "types";
import { tracker } from "utils/analytics";
import { isShareMode } from "utils/newtools";

import ControlsFooter from "./ControlsFooter/ControlsFooter";
import DragHandle from "./DragHandle";
import Header from "./Header";
import MetaDataFooter from "./MetaDataFooter/MetaDataFooter";
import CollaborationFooter from "./CollaborationFooter/CollaborationFooter";
import ExternalImageWithFallback from "components/ExternalImageWithFallback/ExternalImageWithFallback";
import Translate from "components/Translate/Translate";
import ChangeLogFooter from "components/ChangeLogFooter/ChangeLogFooter";

interface Props {
    result: AnyResource;
    results?: Array<AnyResource>;
    resultIndex?: number;
    deleteItemHandler?: () => void;
    isSelectedResult?: boolean;
    selectResultHandler?: (resultId: string) => void;
    addResourceHandler?: () => void;
    removeResourceHandler?: () => void;
    updatePublicationStatusHandler?: (
        publicationStatus: PublicationStatus,
    ) => void;
    reviewRequestHandler?: (
        requestedReviewer: "WIZENOZE" | "OWNER",
        comment?: string,
    ) => void;
    reviewSubmitHandler?: (
        action: "APPROVE" | "REJECT",
        actionDetails?: string[],
        comment?: string,
    ) => void;
    isHighlighted?: boolean;
    draggable?: {
        attributes: DraggableAttributes;
        listeners: DraggableSyntheticListeners;
    };
}

const ResultCard = ({
    result,
    results,
    resultIndex,
    deleteItemHandler,
    isSelectedResult,
    selectResultHandler,
    addResourceHandler,
    removeResourceHandler,
    updatePublicationStatusHandler,
    reviewRequestHandler,
    reviewSubmitHandler,
    isHighlighted,
    draggable,
}: Props) => {
    const { openResultModal, isResultModalEnabled } = useResultModalContext();
    const readingLevel = result?.readingLevel;
    const isCollectionItem = "isUserProvided" in result;
    const isUserProvided = isCollectionItem && result.isUserProvided;
    const isResultGone = result.pageState === "GONE";
    const clickHandler = () => {
        if (isResultModalEnabled) {
            if (results && resultIndex !== undefined) {
                openResultModal({
                    results: results,
                    initialIndex: resultIndex,
                });
            } else {
                openResultModal({ results: [result], initialIndex: 0 });
            }
        } else {
            result.clickUrl && window.open(result.clickUrl);
        }
        tracker("Link feature clicked", {
            fullUrl: result.clickUrl,
            inIFrame: isResultModalEnabled,
        });
    };
    return (
        <div className={styles.relativeWrapper}>
            <div
                className={styles.container}
                style={{
                    borderColor: isHighlighted
                        ? variables["border-colors-color-border-blue"]
                        : variables["border-colors-color-border-light"],
                    transition: "border-color 5s ease-in-out",
                }}
            >
                <div
                    className={styles.card}
                    style={{
                        backgroundColor: isResultGone
                            ? variables[
                                  "background-colors-color-background-light-off"
                              ]
                            : variables[
                                  "background-colors-color-background-white"
                              ],
                    }}
                >
                    <div style={{ width: "100%", display: "flex", gap: 5 }}>
                        {draggable && <DragHandle draggable={draggable} />}
                        <Header
                            clickHandler={clickHandler}
                            result={result}
                            deleteItemHandler={
                                result.reviewStatus !== "PENDING_REVIEW"
                                    ? deleteItemHandler
                                    : undefined
                            }
                            isSelected={isSelectedResult}
                            addResourceHandler={addResourceHandler}
                            removeResourceHandler={removeResourceHandler}
                        />
                    </div>
                    <div className={styles.body} onClick={clickHandler}>
                        <div className={styles.bodyImageContainer}>
                            <ExternalImageWithFallback
                                src={result.thumbnail}
                                className={styles.bodyImage}
                                alt={result?.title + " thumbnail"}
                            />
                            {!isCollectionItem &&
                                result.resultType === "VIDEO" && (
                                    <FontAwesomeIcon
                                        icon={faPlayCircle}
                                        className={styles.bodyVideoIcon}
                                    />
                                )}
                        </div>
                        <div className={styles.bodyTexts}>
                            <Typography
                                variant="text-size-Base-bold"
                                color={
                                    isResultGone
                                        ? "$text-colors-color-text-light-gray.main"
                                        : "$text-colors-color-text-blue.main"
                                }
                            >
                                {isUserProvided &&
                                    result.title === result.clickUrl && (
                                        <Tooltip
                                            title={
                                                <Typography variant="text-size-xxSmall-regular">
                                                    <Translate text="We can’t reach the resource." />
                                                </Typography>
                                            }
                                            placement="top-start"
                                            arrow
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            <FontAwesomeIcon
                                                icon={faWarning}
                                                color={
                                                    variables[
                                                        "message-colors-color-message-warning"
                                                    ]
                                                }
                                                style={{ marginRight: 10 }}
                                            />
                                        </Tooltip>
                                    )}
                                {result.title}
                            </Typography>
                            <Typography
                                variant="text-size-Small-regular"
                                color={
                                    isResultGone
                                        ? "$text-colors-color-text-light-gray.main"
                                        : "$text-colors-color-text-dark.main"
                                }
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: result.description,
                                    }}
                                />
                            </Typography>
                        </div>
                    </div>
                    <MetaDataFooter
                        readingLevel={readingLevel}
                        result={result}
                    />
                    <ChangeLogFooter
                        type="Added by"
                        name={result.createdByName}
                        date={result.createdDate}
                    />
                    {updatePublicationStatusHandler && (
                        <CollaborationFooter
                            updatePublicationStatusHandler={
                                updatePublicationStatusHandler
                            }
                            reviewStatus={result.reviewStatus}
                            reviewRequestHandler={reviewRequestHandler}
                            reviewSubmitHandler={reviewSubmitHandler}
                        />
                    )}
                    {selectResultHandler && !isShareMode && (
                        <ControlsFooter
                            isSelectedResult={isSelectedResult}
                            result={result}
                            selectResultHandler={selectResultHandler}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ResultCard;
