import { useContext } from "react";
import { Typography } from "@mui/material";

import * as styles from "./CommentsPanel.module.scss";
import { CollectionContext } from "../state/CollectionContext";
import { useActionsQuery } from "hooks/api/collections";

import AddCommentButton from "./AddCommentButton";
import Comment from "./Comment";
import Translate from "components/Translate/Translate";

const CommentsPanel = () => {
    const { collection } = useContext(CollectionContext);
    const { data: actionsData } = useActionsQuery(collection.id);
    return (
        <div className={styles.panel}>
            <div className={styles.header}>
                <Typography
                    variant="text-size-xSmall-bold"
                    color="$text-colors-color-text-dark.main"
                    className={styles.title}
                >
                    <Translate text="Comments" />
                </Typography>
            </div>
            <div className={styles.comments}>
                <AddCommentButton />
                {!actionsData || actionsData.length === 0 ? (
                    <div className={styles.noComments}>
                        <Typography
                            variant="text-size-xSmall-medium"
                            color="$text-colors-color-text-gray.main"
                        >
                            <Translate text="No comments" />
                        </Typography>
                    </div>
                ) : (
                    actionsData.map((comment) => (
                        <Comment key={comment.id} comment={comment} />
                    ))
                )}
            </div>
        </div>
    );
};

export default CommentsPanel;
