import {
    DraggableAttributes,
    DraggableSyntheticListeners,
} from "@dnd-kit/core";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "./CollectionGridResultCard.module.scss";
import { useResultModalContext } from "contexts/ResultModalContext";
import wizenozePlaceholder from "images/wizenoze-placeholder.webp";
import variables from "styles/_variables.module.scss";
import { CollectionResourceResult, PublicationStatus } from "types";
import { tracker } from "utils/analytics";

import ChangeLogFooter from "components/ChangeLogFooter/ChangeLogFooter";
import ExternalImageWithFallback from "components/ExternalImageWithFallback/ExternalImageWithFallback";
import CollaborationFooter from "components/ResultCard/CollaborationFooter/CollaborationFooter";
import DragHandle from "components/ResultCard/DragHandle";
import Header from "components/ResultCard/Header";
import MetaDataFooter from "components/ResultCard/MetaDataFooter/MetaDataFooter";
import ResultCardBody from "features/CurriculumShareViewer/QueryResults/ResultsGrid/ResultCard/ResultCardBody/ResultCardBody";
import ResultLink from "features/ResultLink/ResultLink";

interface Props {
    results: CollectionResourceResult[];
    resultIndex: number;
    deleteItemHandler?: () => void;
    updatePublicationStatusHandler?: (
        publicationStatus: PublicationStatus,
    ) => void;
    reviewRequestHandler?: (
        requestedReviewer: "WIZENOZE" | "OWNER",
        comment?: string,
    ) => void;
    reviewSubmitHandler?: (
        action: "APPROVE" | "REJECT",
        actionDetails?: string[],
        comment?: string,
    ) => void;
    stripSummaryText?: boolean;
    isHighlighted?: boolean;
    draggable?: {
        attributes: DraggableAttributes;
        listeners: DraggableSyntheticListeners;
    };
}

const CollectionGridResultCard = ({
    results,
    resultIndex,
    deleteItemHandler,
    updatePublicationStatusHandler,
    reviewRequestHandler,
    reviewSubmitHandler,
    stripSummaryText,
    isHighlighted,
    draggable,
}: Props) => {
    const { openResultModal, isResultModalEnabled } = useResultModalContext();
    const result = results[resultIndex];
    const readingLevel = result?.readingLevel;
    const isResultGone = result.pageState === "GONE";
    const clickHandler = () => {
        if (isResultModalEnabled) {
            openResultModal({
                results: results,
                initialIndex: resultIndex,
            });
        } else {
            if (result.clickUrl) window.open(result.clickUrl);
        }
        tracker("Link feature clicked", {
            fullUrl: result.clickUrl,
            inIFrame: isResultModalEnabled,
        });
    };
    return (
        <div
            className={styles.card}
            style={{
                backgroundColor: isResultGone
                    ? variables["background-colors-color-background-light-off"]
                    : variables["background-colors-color-background-white"],
                borderColor: isHighlighted
                    ? variables["border-colors-color-border-blue"]
                    : variables["border-colors-color-border-light"],
            }}
        >
            <div className={styles.container}>
                <div style={{ width: "100%", display: "flex", gap: 5 }}>
                    {draggable && <DragHandle draggable={draggable} />}
                    <Header
                        clickHandler={clickHandler}
                        result={result}
                        deleteItemHandler={
                            result.reviewStatus !== "PENDING_REVIEW"
                                ? deleteItemHandler
                                : undefined
                        }
                    />
                </div>
                {result?.thumbnail && (
                    <div className={styles.visualContainer}>
                        <ResultLink
                            results={results}
                            initialIndex={resultIndex}
                            styles={styles.imageContainer}
                        >
                            <ExternalImageWithFallback
                                src={result.thumbnail || wizenozePlaceholder}
                                alt={result?.title + " thumbnail"}
                                className={styles.img}
                            />
                            {result.resultType === "VIDEO" && (
                                <div className={styles.videoIconContainer}>
                                    <FontAwesomeIcon
                                        icon={faPlayCircle}
                                        className={styles.videoIcon}
                                        title="video-icon"
                                    />
                                </div>
                            )}
                        </ResultLink>
                    </div>
                )}
                <div className={styles.textContainer}>
                    <ResultCardBody
                        results={results}
                        resultIndex={resultIndex}
                        stripSummaryText={stripSummaryText}
                    />
                </div>
                <MetaDataFooter readingLevel={readingLevel} result={result} />
                <ChangeLogFooter
                    type="Added by"
                    name={result.createdByName}
                    date={result.createdDate}
                />
                {updatePublicationStatusHandler && (
                    <CollaborationFooter
                        updatePublicationStatusHandler={
                            updatePublicationStatusHandler
                        }
                        reviewStatus={result.reviewStatus}
                        reviewRequestHandler={reviewRequestHandler}
                        reviewSubmitHandler={reviewSubmitHandler}
                    />
                )}
            </div>
        </div>
    );
};

export default CollectionGridResultCard;
