@use "../../../styles/variables.module";

.panel {
    padding: 5px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: variables.$background-colors-color-background-white;
    border: 1px solid variables.$border-colors-color-border-primary;
    border-radius: 15px;
    @media (max-width: variables.$bp-lg) {
        flex: 1;
        border-radius: 0px;
        overflow-y: auto;
    }
}

.header {
    padding-left: 10px;
    height: 31px;
    display: flex;
    align-self: stretch;
    border-bottom: 2px solid variables.$border-colors-color-border-primary;
}

.title {
    padding: 10px;
    height: 31px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid variables.$border-colors-color-border-dark;
}

.comments {
    padding: 10px 10px 0px 10px;
    max-height: 700px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
    overflow-y: auto;
    @media (max-width: variables.$bp-lg) {
        max-height: unset;
        overflow-y: unset;
    }
}

.addComment {
    padding: 10px 0px;
    height: 30px;
    gap: 10px;
}

.newComment {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 10px;
}

.noComments {
    margin: 10px 0px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    border-bottom: 1px solid variables.$border-colors-color-border-light;
}

.comment {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 5px;
}

.commentHeader {
    display: flex;
    align-self: stretch;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
    border-bottom: 1px solid variables.$border-colors-color-border-light;
    overflow: hidden;
}

.iconAndName {
    display: flex;
    align-items: center;
    gap: 5px;
    overflow: hidden;
}

.name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.newCommentFooter {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.footerButton {
    padding: 10px;
    height: 24px;
    font-size: 12px;
}
