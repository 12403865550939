import { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import { faBell, faGear, faUserCog } from "@fortawesome/free-solid-svg-icons";

import styles from "./Settings.module.scss";
import { translate } from "components/Translate/Translate";
import { useAppContext } from "contexts/AppContext";
import MessagingContext from "contexts/MessagingContext";
import useScreenSizes from "hooks/useScreenSizes";

import ProfileTab from "./ProfileTab/ProfileTab";
import ReviewRequestsTab from "./ReviewRequestsTab/ReviewRequestsTab";
import SettingsTab from "./SettingsTab/SettingsTab";
import CollectionNavBar from "components/NavBars/CollectionNavBar";
import TabTitle from "components/Tabs/TabTitle/TabTitle";

type SettingsTabs = "profile" | "settings" | "review_requests";

const Settings = () => {
    const { isMobileScreen } = useScreenSizes();
    const [searchParams] = useSearchParams();
    const initialTabParam = searchParams.get("tab");
    const initialTab =
        initialTabParam === "profile" ||
        initialTabParam === "settings" ||
        initialTabParam === "review_requests"
            ? initialTabParam
            : "settings";
    const {
        config: { language },
    } = useAppContext();
    const { openMessageModal } = useContext(MessagingContext);
    const [tabKey, setTabKey] = useState<SettingsTabs>(initialTab);
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
    const changeTab = (
        _event: React.SyntheticEvent,
        newTabKey: SettingsTabs,
    ) => {
        if (hasUnsavedChanges)
            return openMessageModal({
                isModalVisible: true,
                title: "Changes are not saved",
                body: "The changes you made will be lost if you navigate away from this page. Are you sure you want to discard the changes?",
                type: "Yes, leave the page",
                primaryActionHandler: () => {
                    setTabKey(newTabKey);
                    setUnsavedChanges(false);
                },
                secondaryActionHandler: () => {
                    // do nothing
                },
            });
        setTabKey(newTabKey);
        setUnsavedChanges(false);
    };
    return (
        <>
            <CollectionNavBar pageTitle={translate(language, "Settings")} />
            <div style={{ padding: isMobileScreen ? "0px 10px" : "0px 40px" }}>
                <Tabs
                    value={tabKey}
                    variant="fullWidth"
                    onChange={changeTab}
                    className={styles.tabs}
                >
                    <Tab
                        label={
                            <TabTitle
                                label="Settings"
                                icon={faGear}
                                isTabSelected={tabKey === "settings"}
                            />
                        }
                        value="settings"
                    />
                    <Tab
                        label={
                            <TabTitle
                                label="My profile"
                                icon={faUserCog}
                                isTabSelected={tabKey === "profile"}
                            />
                        }
                        value="profile"
                    />
                    <Tab
                        label={
                            <TabTitle
                                label="Review requests"
                                icon={faBell}
                                isTabSelected={tabKey === "review_requests"}
                            />
                        }
                        value="review_requests"
                    />
                </Tabs>
                {tabKey === "settings" && (
                    <SettingsTab
                        setUnsavedChanges={setUnsavedChanges}
                        hasUnsavedChanges={hasUnsavedChanges}
                    />
                )}
                {tabKey === "profile" && (
                    <ProfileTab
                        setUnsavedChanges={setUnsavedChanges}
                        hasUnsavedChanges={hasUnsavedChanges}
                    />
                )}
                {tabKey === "review_requests" && <ReviewRequestsTab />}
            </div>
        </>
    );
};

export default Settings;
