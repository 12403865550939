import { useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, TextField, Typography } from "@mui/material";

import * as styles from "./CollaborationFooter.module.scss";
import { useAppContext } from "contexts/AppContext";

import ContextMenu from "components/ContextMenu/ContextMenu";
import DialogBox from "components/DialogBox/DialogBox";
import Translate, { translate } from "components/Translate/Translate";

interface Props {
    reviewRequestHandler: (
        requestedReviewer: "WIZENOZE" | "OWNER",
        comment?: string,
    ) => void;
    isDisabled: boolean;
}

const ReviewRequestButton = ({ reviewRequestHandler, isDisabled }: Props) => {
    const {
        config: { language },
    } = useAppContext();
    const [isVisible, setIsVisible] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isReviewRequestSent, setIsReviewRequestSent] = useState(false);
    const [requestedReviewer, setRequestedReviewer] = useState<
        "WIZENOZE" | "OWNER"
    >("WIZENOZE");
    const [comment, setComment] = useState<string | undefined>(undefined);
    return (
        <>
            <ContextMenu
                options={[
                    {
                        label: "Send request review to Wizenoze",
                        clickHandler: () => {
                            setIsDialogOpen(true);
                            setRequestedReviewer("WIZENOZE");
                        },
                    },
                    {
                        label: "Send request review to Owner of the Workspace",
                        clickHandler: () => {
                            setIsDialogOpen(true);
                            setRequestedReviewer("OWNER");
                        },
                    },
                ]}
                onClose={() => setIsVisible(false)}
                disabled={isDisabled}
            >
                <Button
                    variant="contained"
                    disabled={isDisabled}
                    className={styles.buttonStyle}
                >
                    <Typography variant="button-text-size-xSmall-bold">
                        <Translate text="Review request" />
                    </Typography>
                    <FontAwesomeIcon
                        icon={isVisible ? faChevronUp : faChevronDown}
                        fontSize={12}
                    />
                </Button>
            </ContextMenu>
            {isDialogOpen && (
                <DialogBox
                    isOpen={isDialogOpen}
                    closeHandler={() => {
                        setIsDialogOpen(false);
                        setIsReviewRequestSent(false);
                        setComment(undefined);
                    }}
                    title={
                        isReviewRequestSent
                            ? "Thank you!"
                            : "Request review of the resource"
                    }
                    withCloseButton
                    styleOverrides={{ width: "435px", height: "unset" }}
                    secondaryButton={
                        isReviewRequestSent
                            ? undefined
                            : {
                                  label: "Cancel",
                                  clickHandler: () => setIsDialogOpen(false),
                              }
                    }
                    primaryButton={
                        isReviewRequestSent
                            ? {
                                  label: "OK",
                                  clickHandler: () => {
                                      setIsDialogOpen(false);
                                      setIsReviewRequestSent(false);
                                  },
                              }
                            : {
                                  label: "Save",
                                  clickHandler: () => {
                                      setIsReviewRequestSent(true);
                                      reviewRequestHandler(
                                          requestedReviewer,
                                          comment,
                                      );
                                  },
                              }
                    }
                    footerStyleOverrides={{ justifyContent: "flex-end" }}
                >
                    <Box
                        sx={{
                            padding: "10px 30px",
                            width: "100%",
                            backgroundColor:
                                "$background-colors-color-background-light.main",
                        }}
                    >
                        {isReviewRequestSent ? (
                            <Typography variant="text-size-Base">
                                <Translate text="The resource has been sent for a review." />
                            </Typography>
                        ) : (
                            <TextField
                                value={comment}
                                placeholder={translate(
                                    language,
                                    "Provide a comment to help reviewer verify the resource.",
                                )}
                                helperText={translate(
                                    language,
                                    "Comment is optional",
                                )}
                                fullWidth
                                multiline
                                minRows={5}
                                onBlur={(e) => setComment(e.target.value)}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        )}
                    </Box>
                </DialogBox>
            )}
        </>
    );
};

export default ReviewRequestButton;
