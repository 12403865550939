import { useState } from "react";
import { faComment } from "@fortawesome/free-solid-svg-icons";

import useScreenSizes from "hooks/useScreenSizes";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import CommentsPanel from "./CommentsPanel";
import MenuButton from "components/MenuButton/MenuButton";
import MenuContainer from "components/MenuContainer/MenuContainer";

const MobileCommentsPanel = () => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    const [isCommentsPanelOpen, setIsCommentsPanelOpen] = useState(false);
    if (isDesktopScreen) return <></>;
    return (
        <>
            <MenuButton
                faIcon={faComment}
                iconColor={variables["icon-colors-blue-100"]}
                primaryColor={variables["icon-colors-blue-40"]}
                secondaryColor={variables["icon-colors-blue-60"]}
                isOpen={isCommentsPanelOpen}
                styleOverrides={{
                    width: "30px",
                    minWidth: "30px",
                    height: "30px",
                    fontSize: "12px",
                }}
                onClick={() => {
                    tracker("Comments panel menu open");
                    setIsCommentsPanelOpen(true);
                }}
            />
            <MenuContainer
                isOpen={isCommentsPanelOpen}
                closeMenu={() => setIsCommentsPanelOpen(false)}
                containerRelativePosition={
                    isMobileScreen
                        ? "bottom-right"
                        : "left-full-height-half-width"
                }
                fullWidthBreakpoint="xl"
                fullHeightBreakpoint="xl"
            >
                <CommentsPanel />
            </MenuContainer>
        </>
    );
};

export default MobileCommentsPanel;
