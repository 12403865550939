import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    faBell,
    faChevronDown,
    faChevronUp,
    faGear,
    faGraduationCap,
    faListUl,
    faQuestion,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

import * as styles from "./UserMenu.module.scss";
import { useReviewRequestsQuery } from "hooks/api/collaboration";
import { useUserCurrentLayerQuery } from "hooks/api/useUserSettingsQuery";
import variables from "styles/_variables.module.scss";
import { tracker } from "utils/analytics";

import FooterItem from "./FooterItem/FooterItem";
import LogoutItem from "./LogoutItem/LogoutItem";
import MenuItem from "./MenuItem/MenuItem";
import UserItem from "./UserItem/UserItem";
import MenuButton from "components/MenuButton/MenuButton";
import MenuContainer from "components/MenuContainer/MenuContainer";
import Translate from "components/Translate/Translate";

const UserMenu = () => {
    const navigate = useNavigate();
    const { data: userCurrentLayer } = useUserCurrentLayerQuery();
    const curriculumId = userCurrentLayer?.curriculumId;
    const gradeId = userCurrentLayer?.gradeId;
    const { data: reviewRequestsData } = useReviewRequestsQuery();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        if (!isMenuOpen) {
            tracker("User menu open");
            setIsMenuOpen(!isMenuOpen);
        } else {
            tracker("User menu closed");
            setIsMenuOpen(!isMenuOpen);
        }
    };
    return (
        <div
            style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                gap: "5px",
            }}
            onClick={toggleMenu}
        >
            <MenuButton
                dataTestId={"userMenuButton"}
                faIcon={faUser}
                iconColor={variables["icon-colors-green-100"]}
                primaryColor={variables["icon-colors-green-40"]}
                secondaryColor={variables["icon-colors-green-60"]}
                isOpen={isMenuOpen}
                onClick={toggleMenu}
            />
            <FontAwesomeIcon
                icon={isMenuOpen ? faChevronUp : faChevronDown}
                fontSize={15}
                color={variables["icon-colors-green-60"]}
                style={{
                    width: "20px",
                    cursor: "pointer",
                }}
            />
            <MenuContainer isOpen={isMenuOpen} closeMenu={toggleMenu}>
                <>
                    <UserItem closeMenu={toggleMenu} />
                    <MenuItem
                        faIcon={faGraduationCap}
                        iconColor={"Blue"}
                        text="Curriculum"
                        clickHandler={() => {
                            tracker("Curriculum button clicked");
                            navigate(
                                `/curriculum/${
                                    curriculumId +
                                    (gradeId ? "/grade/" + gradeId : "")
                                }`,
                            );
                            toggleMenu();
                        }}
                    />
                    <MenuItem
                        faIcon={faListUl}
                        iconColor={"Blue"}
                        text="Collections"
                        clickHandler={() => {
                            tracker("Collections button clicked");
                            navigate("/collections");
                            toggleMenu();
                        }}
                    />
                    <MenuItem
                        faIcon={faBell}
                        iconColor={"Green"}
                        text={
                            <div className={styles.reviewRequests}>
                                <Typography
                                    variant="text-size-Base"
                                    color="$text-colors-color-text-dark.main"
                                >
                                    <Translate text="Review requests" />
                                </Typography>
                                {reviewRequestsData &&
                                    reviewRequestsData.length > 0 && (
                                        <Typography
                                            variant="text-size-xSmall-medium"
                                            color="$text-colors-color-text-dark.main"
                                            className={styles.requestsNumber}
                                        >
                                            {reviewRequestsData.length}
                                        </Typography>
                                    )}
                            </div>
                        }
                        clickHandler={() => {
                            tracker("Review requests button clicked");
                            navigate("/settings?tab=review_requests");
                            toggleMenu();
                        }}
                    />
                    <MenuItem
                        faIcon={faGear}
                        iconColor={"Green"}
                        text="Settings"
                        clickHandler={() => {
                            tracker("Settings button clicked");
                            navigate("/settings");
                            toggleMenu();
                        }}
                    />
                    <MenuItem
                        faIcon={faQuestion}
                        iconColor={"Green"}
                        text="FAQ"
                        clickHandler={() => {
                            tracker("FAQ button clicked");
                            window.open(
                                "https://wizenoze.zendesk.com/hc/en-gb",
                                "_blank",
                            );
                            toggleMenu();
                        }}
                    />
                    <LogoutItem />
                    <FooterItem />
                </>
            </MenuContainer>
        </div>
    );
};

export default UserMenu;
