import { createContext } from "react";
import dayjs from "dayjs";

import {
    Collection,
    CollectionChangeQueue,
    CollectionItem,
    PublicationStatus,
    UnsavedCollection,
} from "types";

export const NewCollection: UnsavedCollection = {
    id: 0,
    title: "Insert collection title",
    description: "",
    imageUrl: "",
    tags: [],
    createdDate: dayjs().toISOString(),
    resultModal: false,
    language: "en",
    view: "LIST",
};

export const organizeArrayBToMatchArrayA = (
    arrayA: Array<CollectionItem>,
    arrayB: Array<CollectionItem>,
    isNewlyOrderedItems: boolean,
) => {
    // Create a copy of arrayB to avoid mutating the original array
    const sortedArrayB = [...arrayB];
    sortedArrayB.sort((a, b) => {
        const orderA = arrayA.findIndex((item) =>
            !isNewlyOrderedItems && item.id
                ? item.id === a.id
                : item.indexId
                  ? item.indexId === a.indexId
                  : item.url === a.url,
        );
        const orderB = arrayA.findIndex((item) =>
            !isNewlyOrderedItems && item.id
                ? item.id === b.id
                : item.indexId
                  ? item.indexId === b.indexId
                  : item.url === b.url,
        );
        return orderA - orderB;
    });

    return sortedArrayB;
};

export const CollectionContext = createContext<{
    collection: UnsavedCollection | Collection;
    updateCollection: (key: keyof Collection, value: unknown) => void;
    selectedItems: CollectionItem[];
    highlightedItems: number[];
    addItem?: (item: CollectionItem) => void;
    updateItemPublicationStatus: (
        item: CollectionItem,
        publicationStatus: PublicationStatus,
    ) => void;
    itemReviewRequest: (
        item: CollectionItem,
        requestedReviewer: "WIZENOZE" | "OWNER",
        comment?: string,
    ) => void;
    itemReviewSubmit: (
        item: CollectionItem,
        action: "APPROVE" | "REJECT",
        actionDetails?: string[],
        comment?: string,
    ) => void;
    removeItem?: (item: CollectionItem) => void;
    reorderItems: (items: Array<CollectionItem>) => void;
    addTag: (value: string) => void;
    removeTag: (value: string) => void;
    changeQueue: CollectionChangeQueue;
    saveHandler: () => void;
    isSaving: boolean;
    deleteHandler: () => void;
}>({
    collection: NewCollection,
    updateCollection: (_key, _value) => {
        return;
    },
    selectedItems: [],
    highlightedItems: [],
    reorderItems: (_items) => {
        return;
    },
    updateItemPublicationStatus: (_value) => {
        return;
    },
    itemReviewRequest: (_value) => {
        return;
    },
    itemReviewSubmit: (_value) => {
        return;
    },
    addTag: (_value) => {
        return;
    },
    removeTag: (_id) => {
        return;
    },
    changeQueue: [],
    saveHandler: () => {
        return;
    },
    isSaving: false,
    deleteHandler: () => {
        return;
    },
});
