import dayjs from "dayjs";
import { faCircle, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Typography } from "@mui/material";

import * as styles from "./CommentsPanel.module.scss";
import variables from "styles/_variables.module.scss";
import { CollectionAction } from "types";

import Translate from "components/Translate/Translate";

interface Props {
    comment: CollectionAction;
}

const Comment = ({ comment }: Props) => {
    return (
        <div className={styles.comment}>
            <div className={styles.commentHeader}>
                <Typography
                    variant="text-size-xxSmall-medium"
                    color="$text-colors-color-text-dark.main"
                    className={styles.iconAndName}
                >
                    <FontAwesomeIcon icon={faCircleUser} fontSize={14} />
                    <span className={styles.name}>{comment.user}</span>
                </Typography>
                <FontAwesomeIcon
                    icon={faCircle}
                    color={variables["icon-colors-green-60"]}
                    fontSize={4}
                />
                <Typography
                    variant="text-size-xxSmall-medium"
                    color="$text-colors-color-text-gray.main"
                >
                    {dayjs(new Date()).format("DD/MM/YY")}
                </Typography>
            </div>
            {comment.item?.title && (
                <Typography
                    variant="text-size-xSmall-bold"
                    color="$text-colors-color-text-dark.main"
                >
                    {comment.item.title}
                </Typography>
            )}
            {comment.type !== "COMMENT" && (
                <Typography
                    variant="text-size-xSmall-medium"
                    color={
                        comment.type === "REVIEW_COMPLETED"
                            ? comment.action === "APPROVE"
                                ? "$message-colors-color-message-success.main"
                                : "$message-colors-color-message-danger.main"
                            : "$message-colors-color-message-warning.main"
                    }
                >
                    <Translate
                        text={
                            comment.type === "REVIEW_COMPLETED"
                                ? comment.action === "APPROVE"
                                    ? "Approved"
                                    : "Rejected"
                                : "Review requested"
                        }
                    />
                </Typography>
            )}
            {comment.actionDetails &&
                comment.actionDetails.map((action, index) => (
                    <Chip key={index} label={action} />
                ))}
            <Typography
                variant="text-size-xSmall-regular"
                color="$text-colors-color-text-dark.main"
            >
                {comment.comment}
            </Typography>
        </div>
    );
};

export default Comment;
