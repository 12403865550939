import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Popper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import * as styles from "./FolderShareViewer.module.scss";
import { translate } from "components/Translate/Translate";
import { useAppContext } from "contexts/AppContext";
import { useViewItemsInCollectionQuery } from "hooks/api/collections";
import {
    useAllCachedShareFoldersQuery,
    useShareElementQuery,
    useShareParams,
} from "hooks/api/useShareQuery";
import useScreenSizes from "hooks/useScreenSizes";
import { AnyElement, ContentElement, Folder, Quiz } from "types";
import { tracker } from "utils/analytics";

import CollectionViewer from "components/CollectionViewer/CollectionViewer";
import CollectionsExplorerPanel from "components/CollectionsExplorerPanel/CollectionsExplorerPanel";
import MobileCollectionsExplorer from "components/CollectionsExplorerPanel/MobileCollectionsExplorer";
import FolderViewer from "components/ContainerElementViewer/ContainerElementViewer";
import Loading from "components/Loading/Loading";
import FolderViewerNavBar from "components/NavBars/FolderViewerNavBar";
import TitleHeader from "components/TitleHeader/TitleHeader";
import Translate from "components/Translate/Translate";
import QuizAvailableFooter from "features/CollectionShareViewer/QuizAvailablePanel/QuizAvailableFooter";

const FolderShareViewer = () => {
    const { isDesktopScreen, isMobileScreen } = useScreenSizes();
    const location = useLocation();
    const navigate = useNavigate();
    const {
        config: { language },
    } = useAppContext();
    const shareParams = useShareParams();
    const selectedElementParams = shareParams.at(-1);
    const {
        data: selectedElementData,
        isInitialLoading: isFetchingSelectedElementData,
    } = useShareElementQuery(selectedElementParams);
    const pageTitle = selectedElementData
        ? selectedElementData.title
        : translate(language, "Collections");
    const { data: collectionItemsData, isFetched: collectionItemsIsFetched } =
        useViewItemsInCollectionQuery(
            selectedElementData?.type === "COLLECTION"
                ? selectedElementParams
                : undefined,
        );
    const [quizData, setQuizData] = useState<Quiz | undefined>(
        selectedElementData?.type === "COLLECTION"
            ? selectedElementData?.quiz
            : undefined,
    );
    const quizAvailableFooterRef = useRef<HTMLDivElement>(null);
    const quizAvailableFooterHeight = quizAvailableFooterRef.current
        ?.clientHeight
        ? quizAvailableFooterRef.current?.clientHeight
        : undefined;
    const elementClickHandler = (element: AnyElement, pathString?: string) => {
        if (element.type === "QUIZ") {
            openQuizHandler(element);
        } else {
            if (!pathString) return;
            tracker("Element clicked", element);
            navigate(pathString);
            if (element.type === "COLLECTION") {
                setQuizData(element?.quiz);
            } else {
                setQuizData(undefined);
            }
        }
    };
    const elementViewClickHandler = (element: AnyElement) => {
        elementClickHandler(
            element,
            [location.pathname, element.id, element.hash].join("/"),
        );
    };
    const openQuizHandler = (quiz: Quiz) => {
        window.open(
            new URL(window.location.href).origin +
                `/quiz/${quiz.id}/${quiz.hash}`,
            "_blank",
        );
    };
    const { data: rootFolder, status: rootFolderStatus } =
        useAllCachedShareFoldersQuery();
    if (rootFolderStatus !== "success") return <Loading />;
    const pathParams = location.pathname.split("/"); // First value is "" as string is "/embed/folder/etc"
    const homePath = [
        "/" + pathParams[1],
        pathParams[2],
        rootFolder.id,
        rootFolder.hash,
    ].join("/");
    const recursivelyJoinIDandHash = (
        elements: Array<Folder | ContentElement>,
    ): Array<Folder | ContentElement> => {
        return elements.map((el) => {
            return {
                ...el,
                pathStep: el.id + "/" + el.hash,
                ...("children" in el
                    ? { children: recursivelyJoinIDandHash(el.children) }
                    : {}),
            };
            return el;
        });
    };
    const treeItems = recursivelyJoinIDandHash(rootFolder.children);
    const sortedSelectedFolderContents =
        selectedElementData?.type === "FOLDER"
            ? selectedElementData?.sortBy === "TITLE"
                ? selectedElementData.children.sort((a, b) =>
                      a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
                  )
                : selectedElementData?.children.sort((a, b) =>
                      a.lastModifiedDate < b.lastModifiedDate ? 1 : -1,
                  )
            : [];
    return (
        <>
            <FolderViewerNavBar />
            {!isDesktopScreen && (
                <MobileCollectionsExplorer
                    items={treeItems}
                    panelTitle={rootFolder?.title}
                    elementClickHandler={elementClickHandler}
                    pathRoot={homePath}
                    selectedIds={shareParams.map((el) => el.id)}
                    useOwnSubheaderContainer
                />
            )}
            <div
                style={{
                    padding: isMobileScreen ? "20px 10px" : "20px 40px",
                    marginBottom: 10,
                    // position: "relative",
                    height: "100%",
                    paddingBottom: quizAvailableFooterHeight,
                }}
            >
                <Grid container spacing={"20px"}>
                    {isDesktopScreen && (
                        <Grid lg={3} xs={0}>
                            <CollectionsExplorerPanel
                                items={treeItems}
                                panelTitle={rootFolder?.title}
                                elementClickHandler={elementClickHandler}
                                pathRoot={homePath}
                                selectedIds={shareParams.map((el) => el.id)}
                            />
                        </Grid>
                    )}
                    <Grid lg={9} xs={12}>
                        {isFetchingSelectedElementData ? (
                            <Loading />
                        ) : selectedElementData?.type === "FOLDER" ? (
                            <>
                                <div className={styles.title}>
                                    <Typography
                                        variant="text-size-Medium"
                                        color="$text-colors-color-text-dark.main"
                                    >
                                        {pageTitle}
                                    </Typography>
                                </div>
                                <FolderViewer
                                    elements={sortedSelectedFolderContents}
                                    isFetched={!isFetchingSelectedElementData}
                                    viewAs={selectedElementData.view ?? "GRID"}
                                    sortBy={
                                        selectedElementData.sortBy ??
                                        "LAST_MODIFIED_DATE"
                                    }
                                    elementClickHandler={
                                        elementViewClickHandler
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <TitleHeader
                                    collectionName={selectedElementData?.title}
                                />
                                {!collectionItemsIsFetched ? (
                                    <Loading />
                                ) : !collectionItemsData ||
                                  !collectionItemsData.length ? (
                                    <div
                                        className={
                                            styles.emptyCollectionMessage
                                        }
                                    >
                                        <Typography variant="text-size-Base">
                                            <Translate text="The collection has no visible resources." />
                                        </Typography>
                                    </div>
                                ) : (
                                    <CollectionViewer
                                        items={collectionItemsData ?? []}
                                        isFetched={collectionItemsIsFetched}
                                        viewAs={
                                            selectedElementData?.view ?? "GRID"
                                        }
                                        collectionResultsModalEnabled={
                                            !!selectedElementData.resultModal
                                        }
                                    />
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
                {selectedElementData?.type === "COLLECTION" && quizData && (
                    <Popper
                        id="simple-popper"
                        open
                        placement="bottom"
                        anchorEl={document.body}
                        style={{ maxWidth: 1440, width: "100%" }}
                    >
                        <Grid container spacing={"20px"}>
                            <Grid lg={3} xs={0}></Grid>
                            <Grid lg={9} xs={12}>
                                <QuizAvailableFooter
                                    ref={quizAvailableFooterRef}
                                    quizTitle={quizData?.title}
                                    openHandler={() =>
                                        openQuizHandler(quizData)
                                    }
                                    styleOverrides={{
                                        position: "static",
                                        marginTop:
                                            -(quizAvailableFooterHeight ?? 20) -
                                            20,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Popper>
                )}
            </div>
        </>
    );
};

export default FolderShareViewer;
