import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Typography } from "@mui/material";

import * as styles from "./CollectionGridResultCard.module.scss";
import { useResultModalContext } from "contexts/ResultModalContext";
import wizenozePlaceholder from "images/wizenoze-placeholder.webp";
import variables from "styles/_variables.module.scss";
import { CollectionResourceResult } from "types";
import { tracker } from "utils/analytics";

import ExternalImageWithFallback from "components/ExternalImageWithFallback/ExternalImageWithFallback";
import CollaborationFooter from "components/ResultCard/CollaborationFooter/CollaborationFooter";
import Header from "components/ResultCard/Header";
import Translate from "components/Translate/Translate";
import ResultCardBody from "features/CurriculumShareViewer/QueryResults/ResultsGrid/ResultCard/ResultCardBody/ResultCardBody";
import ResultLink from "features/ResultLink/ResultLink";

interface Props {
    results: CollectionResourceResult[];
    resultIndex: number;
    deleteItemHandler?: () => void;
    reviewRequestHandler?: (
        requestedReviewer: "WIZENOZE" | "OWNER",
        comment?: string,
    ) => void;
}

const CollectionGridRejectedResultCard = ({
    results,
    resultIndex,
    deleteItemHandler,
    reviewRequestHandler,
}: Props) => {
    const { openResultModal, isResultModalEnabled } = useResultModalContext();
    const result = results[resultIndex];
    const isResultGone = result.pageState === "GONE";
    const clickHandler = () => {
        if (isResultModalEnabled) {
            openResultModal({
                results: results,
                initialIndex: resultIndex,
            });
        } else {
            if (result.clickUrl) window.open(result.clickUrl);
        }
        tracker("Link feature clicked", {
            fullUrl: result.clickUrl,
            inIFrame: isResultModalEnabled,
        });
    };
    return (
        <div
            className={styles.card}
            style={{
                backgroundColor: isResultGone
                    ? variables["background-colors-color-background-light-off"]
                    : variables["background-colors-color-background-white"],
                borderColor: variables["border-colors-color-border-light"],
            }}
        >
            <div className={styles.container}>
                <div style={{ width: "100%", display: "flex", gap: 5 }}>
                    <Header
                        clickHandler={clickHandler}
                        result={result}
                        deleteItemHandler={deleteItemHandler}
                    />
                </div>
                {result?.thumbnail && (
                    <div className={styles.visualContainer}>
                        <ResultLink
                            results={results}
                            initialIndex={resultIndex}
                            styles={styles.imageContainer}
                        >
                            <ExternalImageWithFallback
                                src={result.thumbnail || wizenozePlaceholder}
                                alt={result?.title + " thumbnail"}
                                className={styles.img}
                            />
                            {result.resultType === "VIDEO" && (
                                <div className={styles.videoIconContainer}>
                                    <FontAwesomeIcon
                                        icon={faPlayCircle}
                                        className={styles.videoIcon}
                                        title="video-icon"
                                    />
                                </div>
                            )}
                        </ResultLink>
                    </div>
                )}
                <div className={styles.textContainer}>
                    <ResultCardBody
                        results={results}
                        resultIndex={resultIndex}
                    />
                </div>
                <div className={styles.footer}>
                    <Chip
                        variant="outlined"
                        sx={{
                            color: variables[
                                "message-colors-color-message-danger"
                            ],
                            borderColor:
                                variables[
                                    "message-colors-color-message-danger"
                                ],
                        }}
                        label={
                            <Typography variant="text-size-xxSmall-medium">
                                <Translate text="Rejected" />
                            </Typography>
                        }
                    />
                </div>
                <CollaborationFooter
                    reviewStatus="REJECTED"
                    reviewRequestHandler={reviewRequestHandler}
                />
            </div>
        </div>
    );
};

export default CollectionGridRejectedResultCard;
