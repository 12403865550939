import { useState } from "react";
import {
    faArrowUpFromBracket,
    faChevronDown,
    faChevronUp,
    faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";

import * as styles from "./CollaborationFooter.module.scss";
import { PublicationStatus } from "types";

import ContextMenu from "components/ContextMenu/ContextMenu";
import Translate from "components/Translate/Translate";

interface Props {
    updatePublicationStatusHandler: (
        publicationStatus: PublicationStatus,
    ) => void;
    isDisabled: boolean;
    isVisibleDisabled: boolean;
}

const PublicationStatusButton = ({
    updatePublicationStatusHandler,
    isDisabled,
    isVisibleDisabled,
}: Props) => {
    const [isVisible, setIsVisible] = useState(false);
    return (
        <ContextMenu
            options={[
                {
                    label: "Draft",
                    icon: faEdit,
                    clickHandler: () => updatePublicationStatusHandler("DRAFT"),
                },
                {
                    label: "Visible",
                    icon: faArrowUpFromBracket,
                    clickHandler: () =>
                        updatePublicationStatusHandler("PUBLISHED"),
                    disabled: isVisibleDisabled,
                },
            ]}
            onClose={() => setIsVisible(false)}
            disabled={isDisabled}
        >
            <Button
                variant="contained"
                disabled={isDisabled}
                className={styles.buttonStyle}
            >
                <Typography variant="button-text-size-xSmall-bold">
                    <Translate text="Status" />
                </Typography>
                <FontAwesomeIcon
                    icon={isVisible ? faChevronUp : faChevronDown}
                    fontSize={12}
                />
            </Button>
        </ContextMenu>
    );
};

export default PublicationStatusButton;
