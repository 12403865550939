@use "../../../styles/variables.module";

.reviewRequests {
    display: flex;
    align-items: center;
    gap: 10px;
}

.requestsNumber {
    padding: 5px 8px;
    height: 22px;
    display: flex;
    align-items: center;
    background: #fce6e7;
    border: 1px solid variables.$message-colors-color-message-danger;
    border-radius: 15px;
}
