import { useState } from "react";
import {
    faBan,
    faChevronDown,
    faChevronUp,
    faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";

import * as styles from "../CollaborationFooter.module.scss";

import ApproveDialog from "./ApproveDialog";
import RejectDialog from "./RejectDialog";
import ContextMenu from "components/ContextMenu/ContextMenu";
import Translate from "components/Translate/Translate";

interface Props {
    reviewSubmitHandler: (
        action: "APPROVE" | "REJECT",
        actionDetails?: string[],
        comment?: string,
    ) => void;
}

const ReviewButton = ({ reviewSubmitHandler }: Props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
    const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
    return (
        <>
            <ContextMenu
                options={[
                    {
                        label: "Approve",
                        icon: faCircleCheck,
                        iconColor: "message-colors-color-message-success",
                        clickHandler: () => {
                            setIsApproveDialogOpen(true);
                        },
                    },
                    {
                        label: "Reject",
                        iconColor: "message-colors-color-message-danger",
                        icon: faBan,
                        clickHandler: () => {
                            setIsRejectDialogOpen(true);
                        },
                    },
                ]}
                onClose={() => setIsVisible(false)}
            >
                <Button variant="contained" className={styles.buttonStyle}>
                    <Typography variant="button-text-size-xSmall-bold">
                        <Translate text="Review" />
                    </Typography>
                    <FontAwesomeIcon
                        icon={isVisible ? faChevronUp : faChevronDown}
                        fontSize={12}
                    />
                </Button>
            </ContextMenu>
            {isApproveDialogOpen && (
                <ApproveDialog
                    isApproveDialogOpen={isApproveDialogOpen}
                    setIsApproveDialogOpen={setIsApproveDialogOpen}
                    reviewSubmitHandler={reviewSubmitHandler}
                />
            )}
            {isRejectDialogOpen && (
                <RejectDialog
                    isRejectDialogOpen={isRejectDialogOpen}
                    setIsRejectDialogOpen={setIsRejectDialogOpen}
                    reviewSubmitHandler={reviewSubmitHandler}
                />
            )}
        </>
    );
};

export default ReviewButton;
