@use "../../styles/variables.module";

.container {
    padding-top: 3px;
    min-height: 24px;
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 5px;
    border-top: 1px solid variables.$border-colors-color-border-light;
    white-space: nowrap;
    overflow: hidden;
}

.iconAndName {
    display: flex;
    align-items: center;
    gap: 5px;
    overflow: hidden;
}

.name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
