import { useContext, useState } from "react";
import {
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
    faAngleDoubleDown,
    faAnglesUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Typography } from "@mui/material";

import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";
import {
    CollectionItem,
    CollectionResourceResult,
    PublicationStatus,
} from "types";
import { convertItemTypeToResultType } from "utils/typeConvertors";

import SortableListResult from "./components/SortableListResult";
import AddResourcePanel from "../AddResourcesPanel/AddResourcePanel";
import RejectedResultCard from "components/ResultCard/RejectedResultCard";
import Translate from "components/Translate/Translate";

const ResourcesContainerListView = () => {
    const {
        selectedItems,
        highlightedItems,
        updateItemPublicationStatus,
        itemReviewRequest,
        itemReviewSubmit,
        removeItem,
    } = useContext(CollectionContext);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const partition: {
        items: CollectionItem[];
        rejectedItems: CollectionItem[];
    } = {
        items: [],
        rejectedItems: [],
    };
    selectedItems.forEach((item) => {
        if (item.reviewStatus !== "REJECTED") {
            partition.items.push(item);
        } else {
            partition.rejectedItems.push(item);
        }
    });
    const results: CollectionResourceResult[] = [
        ...partition.items,
        ...partition.rejectedItems,
    ].map((item) => convertItemTypeToResultType(item));
    return (
        <>
            <AddResourcePanel />
            {partition.items.length > 0 && (
                <SortableContext
                    items={partition.items}
                    strategy={verticalListSortingStrategy}
                >
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                        }}
                    >
                        {partition.items.map((item, index) => (
                            <SortableListResult
                                key={item.url}
                                id={item.id}
                                result={convertItemTypeToResultType(item)}
                                results={results}
                                resultIndex={index}
                                deleteItemHandler={() =>
                                    removeItem && removeItem(item)
                                }
                                updateItemPublicationStatusHandler={(
                                    publicationStatus: PublicationStatus,
                                ) =>
                                    updateItemPublicationStatus(
                                        item,
                                        publicationStatus,
                                    )
                                }
                                itemReviewRequestHandler={(
                                    requestedReviewer: "WIZENOZE" | "OWNER",
                                    comment?: string,
                                ) =>
                                    itemReviewRequest(
                                        item,
                                        requestedReviewer,
                                        comment,
                                    )
                                }
                                itemReviewSubmitHandler={(
                                    action: "APPROVE" | "REJECT",
                                    actionDetails?: string[],
                                    comment?: string,
                                ) =>
                                    itemReviewSubmit(
                                        item,
                                        action,
                                        actionDetails,
                                        comment,
                                    )
                                }
                                isHighlighted={highlightedItems.includes(
                                    item.id,
                                )}
                            />
                        ))}
                    </div>
                </SortableContext>
            )}
            {partition.rejectedItems.length > 0 && (
                <div
                    style={{
                        paddingTop: 30,
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #e8e7e9",
                        }}
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        <Typography variant="text-size-Base-bold">
                            <Translate text="Rejected resources" />
                        </Typography>
                        <FontAwesomeIcon
                            icon={!isCollapsed ? faAnglesUp : faAngleDoubleDown}
                            onClick={() => setIsCollapsed(!isCollapsed)}
                        />
                    </div>
                    <Collapse in={!isCollapsed}>
                        {partition.rejectedItems.map((item, index) => (
                            <RejectedResultCard
                                key={item.url}
                                result={convertItemTypeToResultType(item)}
                                results={results}
                                resultIndex={partition.items.length + index}
                                deleteItemHandler={() =>
                                    removeItem && removeItem(item)
                                }
                                reviewRequestHandler={(
                                    requestedReviewer: "WIZENOZE" | "OWNER",
                                    comment?: string,
                                ) =>
                                    itemReviewRequest(
                                        item,
                                        requestedReviewer,
                                        comment,
                                    )
                                }
                            />
                        ))}
                    </Collapse>
                </div>
            )}
        </>
    );
};

export default ResourcesContainerListView;
