@use "../../styles/variables.module";

.relativeWrapper {
    position: relative;
}
.container {
    background-color: white;
    border-radius: 15px;
    border: 1px solid variables.$border-colors-color-border-light;
    box-shadow: 0px 5px 20px 0px rgba(108, 117, 125, 0.2);
    overflow: hidden;
}

.dragHandle {
    display: flex;
    flex: 0 0 20px;
    align-items: center;
    justify-content: center;
    gap: 1px;
    color: variables.$text-colors-color-text-light-gray;
    cursor: move;
}

.card {
    width: 100%;
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.header {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.source {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.sourceLogo {
    flex-basis: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    max-height: 20px;
}

.sourceUrl {
    text-decoration: none;
    text-wrap: nowrap;
    color: variables.$text-colors-color-text-light-gray;
}

.userProvidedLogo {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: variables.$icon-colors-green-100;
    border-radius: 15px;
    border: 1px solid variables.$icon-colors-green-100;
    background: #eaeaea;
}

.body {
    display: flex;
    width: 100%;
    max-height: 122px;
    gap: 10px;
    text-decoration: none;
    cursor: pointer;
}

.bodyImageContainer {
    position: relative;
    flex-basis: 120px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid variables.$border-colors-color-border-light;
}

.bodyImage {
    width: 120px;
    height: 120px;
}

.bodyVideoIcon {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    color: variables.$message-colors-color-message-danger;
    background-color: variables.$background-colors-color-background-white;
    border-radius: 15px;
}

.bodyTexts {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
}

.footer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    align-items: center;
    gap: 10px;
    border-top: 1px solid variables.$border-colors-color-border-light;
}

.footerChip {
    display: flex;
    align-items: center;
    gap: 5px;
}

.readingLevel {
    display: flex;
    height: 20px;
    padding: 5px 3px 5px 8px;
    align-items: center;
    gap: 5px;
    border-radius: 30px;
    border: 1px solid variables.$icon-colors-green-60;
    background: variables.$background-colors-color-background-white;
}

.readingLevelBubble {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.divider {
    color: variables.$icon-colors-green-60;
    font-size: 0.25rem;
}

.querySelectFeedback {
    position: relative;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: variables.$background-colors-color-background-light-off;
}

.select {
    border: 1px solid variables.$border-colors-color-border-primary;
    border-radius: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.checkbox {
    width: 20px;
    height: 20px;
    margin: 0px;
    cursor: pointer;
}

.deleteButton {
    color: variables.$text-colors-color-text-light-gray;
    cursor: pointer;
    :hover {
        color: variables.$message-colors-color-message-danger;
    }
}
