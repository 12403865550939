import { Chip, Typography } from "@mui/material";

import variables from "styles/_variables.module.scss";
import { PublicationStatus } from "types";

import Translate from "components/Translate/Translate";

interface Props {
    publicationStatus: PublicationStatus;
}

const PublicationStatusChip = ({ publicationStatus }: Props) => {
    if (!publicationStatus) return <></>;
    return publicationStatus === "DRAFT" ? (
        <Chip
            sx={{
                background: variables["reading-levels-color-level-1"],
                borderColor: variables["reading-levels-color-level-1"],
            }}
            label={
                <Typography variant="text-size-xxSmall-medium">
                    <Translate text="Draft" />
                </Typography>
            }
        />
    ) : (
        <Chip
            sx={{
                background: variables["message-colors-color-message-success"],
                borderColor: variables["message-colors-color-message-success"],
            }}
            label={
                <Typography variant="text-size-xxSmall-medium">
                    <Translate text="Visible" />
                </Typography>
            }
        />
    );
};

export default PublicationStatusChip;
