import axios, { AxiosInstance } from "axios";
import * as jose from "jose";

import { AppConfig } from "contexts/AppContext";
import { UserMe } from "types";
import { idenitfyUser } from "utils/analytics";
import { localStorageKeys } from "utils/constants";
import { checkLocalStoragePermission, getAppMode } from "utils/newtools";
import { formatVersionString } from "utils/strings";

// Rob: Stolen from: https://stackoverflow.com/a/2117523
// but enhanced for typesafety!
const generateUUIDv4 = () => {
    return ((1e7).toString() + -1e3 + -4e3 + -8e3 + -1e11).replace(
        /[018]/g,
        (c: string) =>
            (
                parseFloat(c) ^
                (crypto.getRandomValues(new Uint8Array(1))[0] &
                    (15 >> (parseFloat(c) / 4)))
            ).toString(16),
    );
};

export const getDeviceID = () => {
    if (!checkLocalStoragePermission()) return ""; // No local storage permission
    const deviceUUIDVersion = window.localStorage.getItem(
        localStorageKeys.DEVICEUUID_VERSION,
    );
    const currentDeviceUUIDVersion = 1;
    if (
        !deviceUUIDVersion ||
        parseInt(deviceUUIDVersion) < currentDeviceUUIDVersion
    ) {
        // A way to control resetting UUIDs when we want to remove existing UUIDs due to bugs
        window.localStorage.removeItem(localStorageKeys.DEVICEUUID);
        window.localStorage.setItem(
            localStorageKeys.DEVICEUUID_VERSION,
            currentDeviceUUIDVersion.toString(),
        );
    }
    const deviceUUID = window.localStorage.getItem(localStorageKeys.DEVICEUUID);
    if (!deviceUUID) {
        window.localStorage.setItem(
            localStorageKeys.DEVICEUUID,
            generateUUIDv4(),
        );
    }
    return deviceUUID;
};

const getParentUrl = () =>
    window.location != window.parent.location ? document.referrer : "";

export const postmanApiBaseURL =
    "https://ef04f997-4b4c-4a2d-8511-9a230c293e7b.mock.pstmn.io";
export const testApiBaseURL = "https://api.test.wizenoze.com/v4";
export const productionApiBaseURL = "https://api.wizenoze.com/v4";

// Singleton that needs initialising before use. Is initialised in the reactRender.tsx root component.
export const axiosAPI = (function () {
    let instance: AxiosInstance | undefined;
    let testInstance: AxiosInstance | undefined;
    const wizenozeStagingEnvironment = process.env.REACT_APP_WIZENOZE_STAGE;
    const createInstance = async (
        config?: Partial<AppConfig>,
        user?: UserMe,
    ) => {
        const params = new URLSearchParams(document.location.search);
        const accountId = params.get("accountId");
        const embedJWToken = params.get("token")?.split("/")[0];
        const secret = new TextEncoder().encode("sdkSettings");
        const jwt = config
            ? await new jose.SignJWT({
                  sdkSettings: config,
              })
                  .setProtectedHeader({ alg: "HS256" })
                  .sign(secret)
            : null;
        const version = formatVersionString(process.env.REACT_APP_VERSION);
        const appMode = getAppMode();
        if (user) {
            idenitfyUser(user);
        } else {
            if (process.env.NODE_ENV !== "production")
                console.warn("App No User");
        }
        return axios.create({
            baseURL:
                wizenozeStagingEnvironment === "test"
                    ? !config && !user
                        ? postmanApiBaseURL
                        : testApiBaseURL
                    : productionApiBaseURL,
            params: {
                // deviceUUID: getDeviceID(),
                ...(user ? { userUUID: user.me.id } : null),
                ...(jwt ? { token: jwt } : null),
            },
            headers: {
                ...(user?.me.authenticationToken
                    ? { Authorization: user?.me.authenticationToken }
                    : null),
                userId: accountId,
                domain: getParentUrl(),
                "x-source-app-name": "wizeup",
                "x-source-app-version": version,
                ...(!accountId && embedJWToken && appMode !== "app"
                    ? { "x-embed-authorization": embedJWToken }
                    : null),
            },
        });
    };

    return {
        isReady: () => !!instance,
        getInstance: (usePostman?: boolean) => {
            if (!instance)
                throw new Error("Tried to use AxiosAPI before intialisation");
            if (usePostman) return testInstance;
            return instance;
        },
        initialise: async (config?: Partial<AppConfig>, user?: UserMe) => {
            instance = await createInstance(config, user);
            if (process.env.REACT_APP_WIZENOZE_STAGE === "test")
                testInstance = await createInstance();
        },
        testInit: async () => {
            instance = await createInstance({});
            // testInstance = await createInstance();
        },
    };
})();
