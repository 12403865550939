import { useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TableSortLabel,
} from "@mui/material";

import { useReviewRequestsQuery } from "hooks/api/collaboration";
import variables from "styles/_variables.module.scss";
import { Request } from "types/collaboration";

import Translate from "components/Translate/Translate";

const ReviewRequestsTab = () => {
    const navigate = useNavigate();
    const { data: reviewRequestsData } = useReviewRequestsQuery();
    const [order, setOrder] = useState<"asc" | "desc">("asc");
    const [orderBy, setOrderBy] = useState<
        keyof Request | "collection.title" | "collection.rootFolder"
    >("collection.title");
    const sortHandler = (
        property: keyof Request | "collection.title" | "collection.rootFolder",
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    if (!reviewRequestsData)
        return (
            <div style={{ paddingTop: 20, textAlign: "center" }}>
                <Typography
                    variant="text-size-Base"
                    color="$text-colors-color-text-dark.main"
                >
                    <Translate text="Currently there are no review requests." />
                </Typography>
            </div>
        );
    const sortedData = [...reviewRequestsData].sort((a, b) => {
        const aValue = orderBy
            .split(".")
            .reduce((o, key) => (o ? o[key] : null), a);
        const bValue = orderBy
            .split(".")
            .reduce((o, key) => (o ? o[key] : null), b);
        if (aValue < bValue) return order === "asc" ? -1 : 1;
        if (aValue > bValue) return order === "asc" ? 1 : -1;
        return 0;
    });
    return (
        <div style={{ paddingTop: 20 }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead
                        sx={{
                            backgroundColor:
                                variables[
                                    "background-colors-color-background-light"
                                ],
                            borderBottomWidth: "3px",
                        }}
                    >
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "collection.title"}
                                    direction={order}
                                    onClick={() =>
                                        sortHandler("collection.title")
                                    }
                                >
                                    <Typography
                                        variant="text-size-Small"
                                        color="$text-colors-color-text-gray.main"
                                    >
                                        <Translate text="Title" />
                                    </Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "type"}
                                    direction={order}
                                    onClick={() => sortHandler("type")}
                                >
                                    <Typography
                                        variant="text-size-Small"
                                        color="$text-colors-color-text-gray.main"
                                    >
                                        <Translate text="Type" />
                                    </Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "collection.rootFolder"}
                                    direction={order}
                                    onClick={() =>
                                        sortHandler("collection.rootFolder")
                                    }
                                >
                                    <Typography
                                        variant="text-size-Small"
                                        color="$text-colors-color-text-gray.main"
                                    >
                                        <Translate text="Course" />
                                    </Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "user"}
                                    direction={order}
                                    onClick={() => sortHandler("user")}
                                >
                                    <Typography
                                        variant="text-size-Small"
                                        color="$text-colors-color-text-gray.main"
                                    >
                                        <Translate text="Name" />
                                    </Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "timestamp"}
                                    direction={order}
                                    onClick={() => sortHandler("timestamp")}
                                >
                                    <Typography
                                        variant="text-size-Small"
                                        color="$text-colors-color-text-gray.main"
                                    >
                                        <Translate text="Date" />
                                    </Typography>
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((row) => (
                            <TableRow
                                key={row.itemId}
                                sx={{
                                    ":hover": {
                                        backgroundColor:
                                            variables[
                                                "background-colors-color-background-light-blue"
                                            ],
                                    },
                                }}
                                onClick={() =>
                                    navigate(
                                        `/edit-collection/${row.collection.id}`,
                                    )
                                }
                            >
                                <TableCell>
                                    <Typography
                                        variant="text-size-xSmall"
                                        color="$text-colors-color-text-dark.main"
                                    >
                                        {row.collection.title}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        sx={{
                                            width: "fit-content",
                                            background:
                                                variables[
                                                    "message-colors-color-message-warning"
                                                ],
                                            borderColor:
                                                variables[
                                                    "message-colors-color-message-warning"
                                                ],
                                        }}
                                        label={
                                            <Typography variant="text-size-xxSmall-medium">
                                                <Translate text="Pending review" />
                                            </Typography>
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="text-size-xSmall"
                                        color="$text-colors-color-text-dark.main"
                                    >
                                        {row.collection.rootFolder}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="text-size-xSmall"
                                        color="$text-colors-color-text-dark.main"
                                    >
                                        {row.user}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        variant="text-size-xSmall"
                                        color="$text-colors-color-text-dark.main"
                                    >
                                        {dayjs(row.timestamp).format(
                                            "DD/MM/YY",
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ReviewRequestsTab;
