import { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";

import { useAppContext } from "contexts/AppContext";

import DialogBox from "components/DialogBox/DialogBox";
import RadioListMultipleSelector from "components/RadioListSelector/RadioListMultipleSelector";
import Translate, { translate } from "components/Translate/Translate";

interface Props {
    isRejectDialogOpen: boolean;
    setIsRejectDialogOpen: (arg0: boolean) => void;
    reviewSubmitHandler: (
        action: "APPROVE" | "REJECT",
        actionDetails?: string[],
        comment?: string,
    ) => void;
}

const RejectDialog = ({
    isRejectDialogOpen,
    setIsRejectDialogOpen,
    reviewSubmitHandler,
}: Props) => {
    const {
        config: { language },
    } = useAppContext();
    const [isReviewSubmitSent, setIsReviewSubmitSent] = useState(false);
    const [selectedActionsIds, setSelectedActionsIds] = useState<
        number[] | undefined
    >(undefined);
    const [selectedActionsDetails, setSelectedActionsDetails] = useState<
        string[] | undefined
    >(undefined);
    const [comment, setComment] = useState<string | undefined>(undefined);
    const actions = [
        {
            id: 1,
            name: translate(language, "Mismatch with learning objective"),
        },
        { id: 2, name: translate(language, "Reading level is too high") },
        { id: 3, name: translate(language, "Reading level is too low") },
        { id: 4, name: translate(language, "Source is not accessible") },
        { id: 5, name: translate(language, "Source is not reliable") },
        { id: 6, name: translate(language, "Other") },
    ];
    return (
        <DialogBox
            isOpen={isRejectDialogOpen}
            closeHandler={() => {
                setIsRejectDialogOpen(false);
                setIsReviewSubmitSent(false);
                setSelectedActionsIds(undefined);
                setSelectedActionsDetails(undefined);
                setComment(undefined);
            }}
            title={isReviewSubmitSent ? "Thank you!" : "Reject the resource"}
            withCloseButton
            styleOverrides={{ width: "435px", height: "unset" }}
            secondaryButton={
                isReviewSubmitSent
                    ? undefined
                    : {
                          label: "Cancel",
                          clickHandler: () => {
                              setIsRejectDialogOpen(false);
                              setIsReviewSubmitSent(false);
                              setSelectedActionsIds(undefined);
                              setSelectedActionsDetails(undefined);
                              setComment(undefined);
                          },
                      }
            }
            primaryButton={
                isReviewSubmitSent
                    ? {
                          label: "OK",
                          clickHandler: () => {
                              setIsRejectDialogOpen(false);
                              setIsReviewSubmitSent(false);
                          },
                      }
                    : selectedActionsDetails
                      ? {
                            label: "Reject",
                            clickHandler: () => {
                                setIsReviewSubmitSent(true);
                                reviewSubmitHandler(
                                    "REJECT",
                                    selectedActionsDetails,
                                    comment,
                                );
                            },
                        }
                      : {
                            label: "Next",
                            clickHandler: () =>
                                setSelectedActionsDetails(
                                    actions
                                        .filter((action) =>
                                            selectedActionsIds.includes(
                                                action.id,
                                            ),
                                        )
                                        .map((action) => action.name),
                                ),
                            disabled:
                                selectedActionsIds === undefined ||
                                selectedActionsIds.length === 0,
                        }
            }
            footerStyleOverrides={{ justifyContent: "flex-end" }}
        >
            <Box
                sx={{
                    padding:
                        !isReviewSubmitSent && !selectedActionsDetails
                            ? "10px 20px"
                            : "10px 30px",
                    width: "100%",
                    backgroundColor:
                        "$background-colors-color-background-light.main",
                }}
            >
                {isReviewSubmitSent ? (
                    <Typography variant="text-size-Base">
                        <Translate text="The resource has been rejected." />
                    </Typography>
                ) : selectedActionsDetails ? (
                    <TextField
                        value={comment}
                        placeholder={translate(
                            language,
                            "Provide a comment to inform the editor the resource has been rejected.",
                        )}
                        helperText={translate(language, "Comment is optional")}
                        fullWidth
                        multiline
                        minRows={5}
                        onBlur={(e) => setComment(e.target.value)}
                        onChange={(e) => setComment(e.target.value)}
                    />
                ) : (
                    <RadioListMultipleSelector
                        itemList={actions}
                        selectedIdsList={selectedActionsIds}
                        setSelectedIdsList={setSelectedActionsIds}
                        styleOverrides={{ padding: 0 }}
                        listStyleOverrides={{ padding: 0 }}
                    />
                )}
            </Box>
        </DialogBox>
    );
};

export default RejectDialog;
