import { useContext } from "react";

import * as styles from "./CollaborationFooter.module.scss";
import { CollectionContext } from "features/CollectionEditor/state/CollectionContext";
import { PublicationStatus, ReviewStatus } from "types";

import PublicationStatusButton from "./PublicationStatusButton";
import ReviewButton from "./ReviewButton/ReviewButton";
import ReviewRequestButton from "./ReviewRequestButton";

interface Props {
    updatePublicationStatusHandler?: (
        publicationStatus: PublicationStatus,
    ) => void;
    reviewStatus: ReviewStatus;
    reviewRequestHandler?: (
        requestedReviewer: "WIZENOZE" | "OWNER",
        comment?: string,
    ) => void;
    reviewSubmitHandler?: (
        action: "APPROVE" | "REJECT",
        actionDetails?: string[],
        comment?: string,
    ) => void;
}

const CollaborationFooter = ({
    updatePublicationStatusHandler,
    reviewStatus,
    reviewRequestHandler,
    reviewSubmitHandler,
}: Props) => {
    const { collection } = useContext(CollectionContext);
    if (!collection.contentReviewSettings) return <></>;
    return (
        <div className={styles.container}>
            {updatePublicationStatusHandler && (
                <PublicationStatusButton
                    updatePublicationStatusHandler={
                        updatePublicationStatusHandler
                    }
                    isDisabled={
                        (collection.contentReviewSettings.reviewProcess ===
                            "MANDATORY" &&
                            reviewStatus !== "APPROVED") ||
                        reviewStatus === "PENDING_REVIEW"
                    }
                    isVisibleDisabled={reviewStatus === "REJECTED"}
                />
            )}
            {reviewRequestHandler &&
                collection.contentReviewSettings.reviewProcess !== "DISABLED" &&
                (!collection.permissions.includes("REVIEW") ||
                    reviewStatus !== "PENDING_REVIEW") && (
                    <ReviewRequestButton
                        reviewRequestHandler={reviewRequestHandler}
                        isDisabled={reviewStatus === "PENDING_REVIEW"}
                    />
                )}
            {reviewSubmitHandler &&
                collection.contentReviewSettings.reviewProcess !== "DISABLED" &&
                collection.permissions.includes("REVIEW") &&
                reviewStatus === "PENDING_REVIEW" && (
                    <ReviewButton reviewSubmitHandler={reviewSubmitHandler} />
                )}
        </div>
    );
};

export default CollaborationFooter;
