import { CSSProperties, Dispatch, SetStateAction } from "react";

import { listContainer } from "./SelectableList.module.scss";

import ListItem from "./ListItem";

type Item = {
    id: number;
    name: string;
};
interface Props {
    list: Array<Item>;
    selectedIdsList: Item["id"][] | undefined;
    setSelectedIdsList:
        | Dispatch<SetStateAction<Item["id"][] | undefined>>
        | ((arg0: Item["id"][] | undefined) => void);
    styleOverrides?: CSSProperties;
}

const MultipleSelectableList = ({
    list,
    selectedIdsList,
    setSelectedIdsList,
    styleOverrides,
}: Props) => (
    <ul className={listContainer} style={{ ...styleOverrides }}>
        {list?.map(({ name, id }) => (
            <ListItem
                key={id}
                name={name}
                id={id}
                selectAction={(id: number) => {
                    if (selectedIdsList === undefined) {
                        setSelectedIdsList([id]);
                    } else if (!selectedIdsList.includes(id)) {
                        setSelectedIdsList([...selectedIdsList, id].sort());
                    } else {
                        setSelectedIdsList(
                            selectedIdsList.filter((el) => el !== id),
                        );
                    }
                }}
                isSelected={
                    selectedIdsList !== undefined &&
                    selectedIdsList.includes(id)
                }
            />
        ))}
    </ul>
);

export default MultipleSelectableList;
