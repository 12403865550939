import { Chip, Tooltip, Typography } from "@mui/material";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as styles from "./ResultCard.module.scss";
import { useResultModalContext } from "contexts/ResultModalContext";
import variables from "styles/_variables.module.scss";
import { AnyResource } from "types";
import { tracker } from "utils/analytics";

import CollaborationFooter from "./CollaborationFooter/CollaborationFooter";
import Header from "./Header";
import Translate from "components/Translate/Translate";

interface Props {
    result: AnyResource;
    results?: Array<AnyResource>;
    resultIndex?: number;
    deleteItemHandler?: () => void;
    reviewRequestHandler?: (
        requestedReviewer: "WIZENOZE" | "OWNER",
        comment?: string,
    ) => void;
}

const RejectedResultCard = ({
    result,
    results,
    resultIndex,
    deleteItemHandler,
    reviewRequestHandler,
}: Props) => {
    const { openResultModal, isResultModalEnabled } = useResultModalContext();
    const isCollectionItem = "isUserProvided" in result;
    const isUserProvided = isCollectionItem && result.isUserProvided;
    const isResultGone = result.pageState === "GONE";
    const clickHandler = () => {
        if (isResultModalEnabled) {
            if (results && resultIndex !== undefined) {
                openResultModal({
                    results: results,
                    initialIndex: resultIndex,
                });
            } else {
                openResultModal({ results: [result], initialIndex: 0 });
            }
        } else {
            result.clickUrl && window.open(result.clickUrl);
        }
        tracker("Link feature clicked", {
            fullUrl: result.clickUrl,
            inIFrame: isResultModalEnabled,
        });
    };
    return (
        <div className={styles.relativeWrapper}>
            <div
                className={styles.container}
                style={{
                    borderColor: variables["border-colors-color-border-light"],
                    transition: "border-color 5s ease-in-out",
                }}
            >
                <div
                    className={styles.card}
                    style={{
                        backgroundColor: isResultGone
                            ? variables[
                                  "background-colors-color-background-light-off"
                              ]
                            : variables[
                                  "background-colors-color-background-white"
                              ],
                    }}
                >
                    <div style={{ width: "100%", display: "flex", gap: 5 }}>
                        <Header
                            clickHandler={clickHandler}
                            result={result}
                            deleteItemHandler={deleteItemHandler}
                        />
                    </div>
                    <div className={styles.body} onClick={clickHandler}>
                        <div className={styles.bodyTexts}>
                            <Typography
                                variant="text-size-Base-bold"
                                color={
                                    isResultGone
                                        ? "$text-colors-color-text-light-gray.main"
                                        : "$text-colors-color-text-blue.main"
                                }
                            >
                                {isUserProvided &&
                                    result.title === result.clickUrl && (
                                        <Tooltip
                                            title={
                                                <Typography variant="text-size-xxSmall-regular">
                                                    <Translate text="We can’t reach the resource." />
                                                </Typography>
                                            }
                                            placement="top-start"
                                            arrow
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            <FontAwesomeIcon
                                                icon={faWarning}
                                                color={
                                                    variables[
                                                        "message-colors-color-message-warning"
                                                    ]
                                                }
                                                style={{ marginRight: 10 }}
                                            />
                                        </Tooltip>
                                    )}
                                {result.title}
                            </Typography>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <Chip
                            variant="outlined"
                            sx={{
                                color: variables[
                                    "message-colors-color-message-danger"
                                ],
                                borderColor:
                                    variables[
                                        "message-colors-color-message-danger"
                                    ],
                            }}
                            label={
                                <Typography variant="text-size-xxSmall-medium">
                                    <Translate text="Rejected" />
                                </Typography>
                            }
                        />
                    </div>
                    <CollaborationFooter
                        reviewStatus="REJECTED"
                        reviewRequestHandler={reviewRequestHandler}
                    />
                </div>
            </div>
        </div>
    );
};

export default RejectedResultCard;
