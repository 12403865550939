import { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";

import { useAppContext } from "contexts/AppContext";

import DialogBox from "components/DialogBox/DialogBox";
import Translate, { translate } from "components/Translate/Translate";

interface Props {
    isApproveDialogOpen: boolean;
    setIsApproveDialogOpen: (arg0: boolean) => void;
    reviewSubmitHandler: (
        action: "APPROVE" | "REJECT",
        actionDetails?: string[],
        comment?: string,
    ) => void;
}

const ApproveDialog = ({
    isApproveDialogOpen,
    setIsApproveDialogOpen,
    reviewSubmitHandler,
}: Props) => {
    const {
        config: { language },
    } = useAppContext();
    const [isReviewSubmitSent, setIsReviewSubmitSent] = useState(false);
    const [comment, setComment] = useState<string | undefined>(undefined);
    return (
        <DialogBox
            isOpen={isApproveDialogOpen}
            closeHandler={() => {
                setIsApproveDialogOpen(false);
                setIsReviewSubmitSent(false);
                setComment(undefined);
            }}
            title={isReviewSubmitSent ? "Thank you!" : "Approve the resource"}
            withCloseButton
            styleOverrides={{ width: "435px", height: "unset" }}
            secondaryButton={
                isReviewSubmitSent
                    ? undefined
                    : {
                          label: "Cancel",
                          clickHandler: () => {
                              setIsApproveDialogOpen(false);
                              setIsReviewSubmitSent(false);
                              setComment(undefined);
                          },
                      }
            }
            primaryButton={
                isReviewSubmitSent
                    ? {
                          label: "OK",
                          clickHandler: () => {
                              setIsApproveDialogOpen(false);
                              setIsReviewSubmitSent(false);
                          },
                      }
                    : {
                          label: "Approve",
                          clickHandler: () => {
                              setIsReviewSubmitSent(true);
                              reviewSubmitHandler(
                                  "APPROVE",
                                  undefined,
                                  comment,
                              );
                          },
                      }
            }
            footerStyleOverrides={{ justifyContent: "flex-end" }}
        >
            <Box
                sx={{
                    padding: "10px 30px",
                    width: "100%",
                    backgroundColor:
                        "$background-colors-color-background-light.main",
                }}
            >
                {isReviewSubmitSent ? (
                    <Typography variant="text-size-Base">
                        <Translate text="The resource has been approved." />
                    </Typography>
                ) : (
                    <TextField
                        value={comment}
                        placeholder={translate(
                            language,
                            "Provide a comment to inform that the resource has been verified and approved.",
                        )}
                        helperText={translate(language, "Comment is optional")}
                        fullWidth
                        multiline
                        minRows={5}
                        onBlur={(e) => setComment(e.target.value)}
                        onChange={(e) => setComment(e.target.value)}
                    />
                )}
            </Box>
        </DialogBox>
    );
};

export default ApproveDialog;
