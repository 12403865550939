import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { AnyResource, PublicationStatus } from "types";

import ResultCard from "components/ResultCard/ResultCard";

interface Props {
    id: number;
    result: AnyResource;
    results: AnyResource[];
    resultIndex: number;
    deleteItemHandler: () => void;
    updateItemPublicationStatusHandler: (
        publicationStatus: PublicationStatus,
    ) => void;
    itemReviewRequestHandler: (
        requestedReviewer: "WIZENOZE" | "OWNER",
        comment?: string,
    ) => void;
    itemReviewSubmitHandler: (
        action: "APPROVE" | "REJECT",
        actionDetails?: string[],
        comment?: string,
    ) => void;
    isHighlighted: boolean;
}

const SortableListResult = ({
    id,
    result,
    results,
    resultIndex,
    deleteItemHandler,
    updateItemPublicationStatusHandler,
    itemReviewRequestHandler,
    itemReviewSubmitHandler,
    isHighlighted,
}: Props) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: "none",
    };
    return (
        <div ref={setNodeRef} style={style}>
            <ResultCard
                result={result}
                results={results}
                resultIndex={resultIndex}
                deleteItemHandler={deleteItemHandler}
                updatePublicationStatusHandler={
                    updateItemPublicationStatusHandler
                }
                reviewRequestHandler={itemReviewRequestHandler}
                reviewSubmitHandler={itemReviewSubmitHandler}
                isHighlighted={isHighlighted}
                draggable={{ attributes: attributes, listeners: listeners }}
            />
        </div>
    );
};

export default SortableListResult;
